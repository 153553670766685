import * as React from 'react'
import Layout from '../components/Layout'
import { graphql, useStaticQuery } from 'gatsby'
import Seo from '../components/Seo'
import useMediaQuery from '../hooks/useMediaQuery'
import useScrollIntoView from '../hooks/useScrollIntoView'
import useHashState from '../hooks/useHashState'
import TailwindScreens from '../helpers/tailwind'
import appendParameter from '../helpers/appendParameter'

const DigitalCatalog = () => {
  const iframeRef = React.useRef(null)
  const isLandScape = useMediaQuery('(orientation: landscape)')
  const isMd = useMediaQuery(TailwindScreens.md)
  const [page] = useHashState('page', null)
  useScrollIntoView(iframeRef, isMd ? -30 : 0)

  const { catalog } = useStaticQuery(graphql`
    query catalogURLRequest {
      catalog: strapiCatalog {
        url: digitalCatalogURL
      }
    }
  `)
  let url = appendParameter(catalog.url, ' ', isLandScape ? 2 : 1)
  if (page) {
    url = appendParameter(url, 'page', page)
  }

  return (
    <Layout className={' mx-auto'} breadcrumbs={false} h1='Digital Catalog'>
      <Seo
        description="Browse the world-renowned Gray and Sons Monthly Printed Catalog online. Be sure to register for updates."
        title="Digital Catalog | Gray and Sons Jewelers"
      />
      <iframe
        ref={iframeRef}
        src={url}
        title="Gray and Sons Digital Catalog"
        className="relative landscape:inset-0 h-full w-full landscape:aspect-video portrait:aspect-[8375/10875] "
      />
    </Layout>
  )
}

export default DigitalCatalog
